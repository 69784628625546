import React, { useState, useEffect } from "react";
import EditBooking from "./EditBooking";

import ViewPersonalBookings from "./ViewPersonalBookings";

function ViewEditBooking(props) {
  const [currentBooking, setCurrentBooking] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const refreshList = () => {
    setCurrentBooking(null);
    setCurrentIndex(-1);
  };

  const setActiveBooking = (booking) => {
    setCurrentBooking({
      key: booking.key,
      bookingName: booking.bookingName,
      bookingGuests: booking.bookingGuests,
      bookingArrival: booking.bookingArrival,
      bookingDeparture: booking.bookingDeparture,
      rooms: booking.rooms,
      bookingUserID: booking.bookingUserID,
    });

    console.log(`Current bookingKey is: ${booking.key}`);
  };

  return (
    <section className="grid lg:grid-cols-2 min-w-full gap-8">
      <div>
        <div className="mb-12">
          <h2 className="text-title1 text-black">Mina bokningar</h2>
          <p className="text-base text-gray-700">
            Här visas alla dina kommande och tidigare bokningar
          </p>
        </div>
        <div className="flex flex-col">
          <ViewPersonalBookings
            userID={props.user.uid}
            setActiveBooking={setActiveBooking}
          />
        </div>
      </div>
      <div className="flex justify-center">
        {currentBooking ? (
          <EditBooking booking={currentBooking} refreshList={refreshList} />
        ) : null}
      </div>
    </section>
  );
}

export default ViewEditBooking;
