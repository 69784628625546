import React from "react";
import Lottie from "react-lottie";
import animationData from "../complete-icon.json";

export default function Complete() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="grid place-items-center mb-20">
      <Lottie options={defaultOptions} height={240} width={240} />
      <p>Bokning inlagd!</p>
    </div>
  );
}
