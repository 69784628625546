import React from "react";
import { PrimaryButton } from "../components/buttons";
import Header from "../components/Header";
import ViewEditBooking from "../components/ViewEditBooking";

function Profil(props) {

  const lastSignInTime = new Date(props.user.metadata.lastSignInTime).toLocaleString('sv-SE',
  {
    year: 'numeric',
    month: 'short',
    day: 'numeric', 
    hour: '2-digit',
    minute: '2-digit'
  });
  return (
    <>
      <Header>
        {props.user ? (
          <>
            <div className="flex items-center">
              <div
                className="relative w-20 h-20 sm:w-40 sm:h-40 rounded-full bg-cover bg-center shadow-xl overflow-hidden flex-shrink-0"
                
              ><img src={props.user.photoURL} alt={props.user.displayName} className="w-full h-full object-cover relative"/></div>
              <div className="ml-6 flex-auto truncate">
              <h1 className="font-title font-semibold text-heading text-white truncate">
                {props.user.displayName}
              </h1>
              <p className="whitespace-normal text-sm text-gray-100 uppercase tracking-wider font-medium">Inloggad sedan {lastSignInTime}</p>
              </div>
            </div>
            <div><PrimaryButton onClick={props.logout} text={'Logga ut'} actionText={'Klicka här för att logga ut från portalen'}/></div>
          </>
        ) : null}
      </Header>
      <main className="grid py-20 pb-32 px-8 lg:px-20 bg-gray-100 justify-self-stretch">
            <ViewEditBooking user={props.user}/>
      </main>
    </>
  );
}

export default Profil;
