import React from "react";
import { NavLink } from "react-router-dom";

function NavPill(props) {
  return (
    <NavLink
      to={props.to}
      className="flex-1 px-8 py-6 flex flex-col text-0 text-gray-400 text-center hover:bg-gray-100 transition-all ease-in-out duration-200"
      activeClassName="text-primary"
    >
      {props.children}
    </NavLink>
  );
}

function Nav() {
  return (
    <nav className="sticky z-50 bottom-0 flex justify-center w-full bg-white border-gray-200 border-t">
      <NavPill to="/">
        <i className="ri-home-line text-title2"></i>Hem
      </NavPill>
      <NavPill to="/boka">
        <i className="ri-add-line text-title2"></i>Boka
      </NavPill>
      <NavPill to="/profil">
        <i className="ri-user-line text-title2"></i>Profil
      </NavPill>
    </nav>
  );
}

export default Nav;
