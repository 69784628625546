import React, { Component } from "react";
import {
  Switch,
  Route,
  HashRouter as Router
} from "react-router-dom";
import 'remixicon/fonts/remixicon.css'

import { auth, provider } from "./firebase.js";
import Login from "./components/Login";
import Nav from "./components/Nav.js";
import Profil from "./pages/Profil.js";
import Boka from "./pages/Boka.js";
import Hem from "./pages/Hem.js";

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      user: {
        loggedIn: false,
        userData: null,
      },
    };

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  handleChange(e) {
    /* ... */
  }

  login() {
    auth.signInWithPopup(provider).then((result) => {
      const user = result.user;
      this.setState({
        user: {
          loggedIn: true,
          userData: user,
        },
      });
    });
  }

  logout() {
    auth.signOut().then(() => {
      this.setState({
        user: {
          loggedIn: false,
          userData: null,
        },
      });
    });
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          user: {
            loggedIn: true,
            userData: user,
          },
        });
      }
    });
  }

  render() {
    return (
      <>
        <div className="App grid">
          
          {this.state.user.userData ? <Router>  <Switch>
            <Route exact path="/profil">
            <Profil user={this.state.user.userData} logout={this.logout} />
          </Route>
          <Route exact path="/boka">
            <Boka user={this.state.user}/>
          </Route>
          <Route exact path="/">
            <Hem />
          </Route>
        </Switch> <Nav /></Router> : <Login login={this.login}/>}
        </div>
      </>
    );
  }
}
