import React from "react";

function Paragraph(props) {
    return <p className="py-1 pr-8 lg:pr-16 mb-4 lg:mb-0 last:mb-0 flex-shrink-0 last:flex-shrink-1">{props.children}</p>
}

function SubLabel(props) {
    return <span className="font-sans text-sm font-medium tracking-wider uppercase block text-secondary">{props.children}</span>
}

function Label(props) {
    return <span className="text-gray-700 text-base">{props.children}</span>
}

function Card(props) {

  const booking = props.booking;
  const rooms = [];

  const getRoomName = () => {
    booking.rooms.forEach(room => {
      rooms.push(room[1]);
    });
  }

  getRoomName();

  return (
    <div className="flex flex-wrap lg:flex-nowrap justify-start rounded-sm mb-7 shadow-xl p-8 bg-white">
      <Paragraph>
        <SubLabel>Bokning gjord av</SubLabel>
        <Label>{booking.bookingName}</Label>
      </Paragraph>
      <Paragraph>
        <SubLabel>Antal gäster</SubLabel>
        <Label>{booking.bookingGuests}</Label>
      </Paragraph>
      <Paragraph>
        <SubLabel>Incheckning</SubLabel>
        <Label>{booking.bookingArrival}</Label>
      </Paragraph>
      <Paragraph>
        <SubLabel>Utcheckning</SubLabel>
        <Label>{booking.bookingDeparture}</Label>
      </Paragraph>
      <Paragraph>
        <SubLabel>Rum/stugor vi kommer använda</SubLabel>
        <Label>{rooms.join(', ')}</Label>
      </Paragraph>

    </div >
  );
}

export default Card;
