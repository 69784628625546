import React, { useState, useEffect } from 'react'
import bookingDB from "../bookingDb";
import Card from "./Card";

function ViewAllBookings(props) {
    const [bookings, setBookings] = useState([]);

    const onDataChange = (items) => {
      let bookings = [];
  
      items.forEach((item) => {
        let key = item.key;
        let data = item.val();
        bookings.push({
          key: key,
          bookingName: data.bookingName,
          bookingGuests: data.bookingGuests,
          bookingArrival: data.bookingArrival,
          bookingDeparture: data.bookingDeparture,
          rooms: data.rooms,
          bookingUserID: data.bookingUserID,
        });
      });
  
      setBookings(bookings);
    };
  
    useEffect(() => {
      bookingDB.getAll().on("value", onDataChange);
  
      return () => {
        bookingDB.getAll().off("value", onDataChange);
      };
    }, []);
  
    return bookings.map((booking, index) => (
      <Card key={index} booking={booking} />
    ));
}

export default ViewAllBookings