import firebase from "firebase";


var firebaseConfig = {
    apiKey: "AIzaSyB_egw543eOkjizxBG2adEMgDe9ATvuMW0",
    authDomain: "stenbrottsvagen-e88eb.firebaseapp.com",
    projectId: "stenbrottsvagen-e88eb",
    storageBucket: "stenbrottsvagen-e88eb.appspot.com",
    messagingSenderId: "220547020978",
    appId: "1:220547020978:web:57314018fc4dfd12c431ab",
    measurementId: "G-0E45XDSGEE"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  export const provider = new firebase.auth.GoogleAuthProvider();
  export const auth = firebase.auth();
  export default firebase;